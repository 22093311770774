import React from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      By <Styled.b>Vincent Datrier</Styled.b>
      <br />
      I build websites and mobile apps!
    </>
  )
}
